.acat-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-bottom: 16px;
  gap: 12px;
  .back-btn {
    align-self: flex-start;
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
  }
  .disclaimer-checkbox-container {
    width: 100%;
    background-color: #e1e1e1;
    color: gray;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    gap: 4px;
    align-items: start;
  }
}